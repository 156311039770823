import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";

function TimeEntryModal(props: any) {
	const context: any = useContext(HomeContext);
	// Form reference
	const [formRef] = useForm();
	const [activeRowKey, setActiveRowKey] = useState<any>(props.selectedUUID);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [timeEntryInfo, setTimeEntryInfo] = useState<any>(null);
	// Cancel Popover State
	const [cancelPopover, setCancelPopover] = useState<any>(false);
	// Delete Popover State
	const [deletePopover, setDeletePopover] = useState<boolean>(false);
	// Form Reset State
	const [formReset, setFormReset] = useState<boolean>(true);

	const handleFormCancel = () => {
		props.setOpenModal(false);
		// setCancelPopover(false);
		resetForm();
	};

	const handleFormSave = async (values: any) => {
		// Iterate through every input in the time entry modal form and store their values in string.
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				// Remove any whitespace in the input value
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("timewriting").then((cruda: any) => {
			if (activeRowKey) {
				const form = cruda.getFormList("timewriting");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				// Runs `updateLookupItem` function in the `CRUDA > timewriting` file and pass data into parameters.
				cruda
					// Put `null` on unused props
					.updateLookupItem(
						null, // name of the file in the CRUDA folder
						null, // the `if else` condition for running the API
						activeRowKey, // uuid to check if the item exists
						null, // current tenant key for perform operations tenant specific data only
						values, // all input data in `getFormList`
						initialForm, // `initialData` before any changes
						null, // object for storing `otherData` props
						null, // user data (optional)
						null // current array of input field `name` values
					)
					.then((updatedItem: any) => {
						// Signals the emitter that has an "alert" keyword attached to it
						Emitter.emit("alert", {
							// Payload for this emitter
							type: "success",
							message: `Time entry has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000, // 3 seconds
						});
						if (props.refreshTimeEntries) props.refreshTimeEntries(updatedItem);
						// A dynamic way to detect page changes while form is being updated
						context?.handleFormUpdating(false, activeRowKey);
						// Reset the form fields with the updated item values
						// resetForm(values, true);
						// console.log(
						// 	"`getCrudaClass` updatedItem",
						// 	updatedItem,
						// 	"values",
						// 	values
						// );
						// setTimeEntryInfo(updatedItem); // Set the updated time entry object into `timeEntryInfo`
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This time entry already exists, please ensure the time entry ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
				console.log(
					"activeRowKey: ",
					activeRowKey,
					"values: ",
					values,
					"initialForm: ",
					initialForm,
					"formList: ",
					formList
				);
			} else {
				console.log("Adding TimeEnetry data", values);
				cruda
					.addLookupItem("timewriting", [], values, null, null, null, null)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Time entry has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");
						resetForm(values, true);
						setActiveRowKey(addedItem?.uuid);
						if (props.refreshTimeEntries) props.refreshTimeEntries(addedItem);
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This time entry already exists, please ensure the time entry ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = () => {
		context?.handleFormUpdating(false, activeRowKey);
		// Delete the time entry item by `activeRowKey` (uuid)
		console.log("Deleting data", activeRowKey);
		// `getCrudaClass` goes into the `CRUDA` folder and iterate through all the file names,
		//  then return the file that matches the `tabKey` value.
		getCrudaClass("timewriting").then((cruda: any) => {
			cruda
				// Runs `deleteLookupItem` and pass in the `activeRowKey` (uuid)
				.deleteLookupItem(null, null, activeRowKey)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Time entry has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					props.deleteTimeEntry(activeRowKey);
					props.setOpenModal(false);
					setDeletePopover(false);
					setActiveRowKey(null);
					resetForm();

					// Redirect to the selected page on delete
					if (props.redirectOnDelete) {
						props.redirectOnDelete();
					}
				})
				.catch((error: any) => {
					console.log("Error during deletion: ", error);
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {});
		});
	};

	// Handle form reset
	const resetForm = (values: any = null, reset: any = true) => {
		// If the form reference exists
		if (formRef) {
			// Set the `formReset` to true
			setFormReset(true);
			// Reset the form fields
			reset && formRef.resetFields();
			// Set the form fields value if values exist
			formRef.setFieldsValue(values ? { ...values } : null);
			// Set the initial form values (for editing)
			setInitialForm(values);
			console.log("`resetForm` Values", values);
		}
	};

	// Handle form reset values
	useEffect(() => {
		// if (props.matchedEntry) {
		resetForm(props.initialValues, true);
		setActiveRowKey(props.initialValues.uuid);
		// console.log("ARK", activeRowKey);
		// }
	}, [props.matchedEntry, props.initialValues]);

	return (
		<Modal
			closable={false}
			width={"40%"}
			open={props.openModal}
			centered
			destroyOnClose
			footer={() => {
				return (
					<div
						style={{
							height: "auto",
							padding: "0",
							width: "100%",
							display: "flex",
							justifyContent: props.matchedEntry ? "space-between" : "flex-end",
							gap: "16px",
						}}
					>
						{/* Delete Popconfirm */}
						{props.matchedEntry && (
							<Popconfirm
								placement="topLeft"
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											Permanently delete this well?
											<br />
											This action cannot be undone.
										</>
									</div>
								}
								open={deletePopover}
								//description="Permanently delete this item? This action cannot be undone."
								onConfirm={handleFormDelete}
								overlayClassName="popconfirm-danger"
								onCancel={() => {
									setDeletePopover(false);
								}}
								okText="Delete"
								okType="danger"
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								cancelText={<span>Cancel</span>}
								cancelButtonProps={{ ghost: true }}
							>
								<Button
									disabled={!activeRowKey}
									className="ant-btn-secondary"
									style={{ marginRight: "auto" }}
									danger
									onClick={() => {
										setDeletePopover(true);
									}}
								>
									Delete
								</Button>
							</Popconfirm>
						)}
						{/* Cancel Popconfirm */}
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							{/* Cancel Button */}
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								Cancel
							</Button>
						</Popconfirm>
						{/* Save Popconfirm */}
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							placement="topRight"
						>
							{/* Save Button */}
							<FormButtonSave
								form={formRef}
								formReset={formReset}
								setFormReset={setFormReset}
								handleFormSave={handleFormSave}
							></FormButtonSave>
						</Popconfirm>
					</div>
				);
			}}
		>
			<div>Time entry</div>
			<CustomForm
				setFormReset={setFormReset}
				formRef={formRef}
				tabKey={"timewriting"} // link to name of the file inside `utils > CRUDA` folder
				// `dataOptions` is an object that contains the data needed for the form
				dataOptions={{
					// Declare the fields and pass props data as objects
					projects: props.projects,
					tenants: props.tenants,
				}}
			/>
		</Modal>
	);
}

export default TimeEntryModal;
