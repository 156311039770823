import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Space, Table, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import FormButtonCancel from "../components/FormButtonCancel";
import FormButtonSave from "../components/FormButtonSave";
import useUpdateAllData from "../hooks/useUpdateAllData";
import {
	getRolePermissions,
	updateRolePermissions,
} from "../services/api-server/roles_permissions";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { checkSuperAdminPriviledge } from "../utils/utils";
import { HomeContext } from "./Home";

function PermissionSettings(props: any) {
	const context: any = useContext(HomeContext);
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [rolePermissionData, setRolePermissionData] = useState<any>(null);
	const [initialTableData, setInitialTableData] = useState<any>(null);
	const [rolePermissionTableData, setRolePermissionTableData] =
		useState<any>(null);

	const { updateAllData } = useUpdateAllData();

	// CONSTANT DATA
	const FEATURES = ["Project", "Well", "Operation"];
	let isSuperAdmin = checkSuperAdminPriviledge(props.params.userRole);
	const ROLES = [
		...(isSuperAdmin ? ["tenant_admin"] : []),
		"tenant_members",
		"project_manager",
		"team_members",
		"general_members",
	];

	const handleCheckboxChange = (
		key: string,
		permission: string,
		role: string,
		checked: boolean
	) => {
		const _perm = [...rolePermissionTableData];
		const feature = _perm.find((feat: any) => feat.key == key);
		console.log(feature);
		let _role = feature.roles[role];
		let foundIndex = _role.findIndex(
			(permissionKeys: any) => permissionKeys == permission
		);
		if (foundIndex != -1 && !checked) {
			_role.splice(foundIndex, 1);
		} else if (checked) {
			_role.push(permission);
		}
		setFormReset(false);
		setRolePermissionTableData(_perm);
	};

	useEffect(() => {
		if (context.currentTenantKey) {
			getRolePermissions(context.currentTenantKey)
				.then((data: any) => {
					const _data = FEATURES.map((f: any, index: any) => {
						const feature: any = {
							key: index,
							feature: f,
							roles: {},
						};

						const filteredRoles = data.filter((item: any) =>
							ROLES.includes(item.key)
						);
						filteredRoles.map((item: any) => {
							const perm = item.permissions.filter((p: any) => p.includes(f));
							feature.roles[item.key] = perm;
						});

						return feature;
					});
					setRolePermissionData(data);
					setRolePermissionTableData([..._data]); // Creates a new reference
					setInitialTableData(JSON.parse(JSON.stringify(_data))); // Deep clone
					resetForm(_data);
				})
				.catch((e) => {
					console.log("Error retrieving role permission data:", e);
				});
		}
	}, [context.allData]);

	const getPermissionsByFeature = (feature: string) => {
		if (feature === "Operation") {
			return ["View", "Create", "Edit", "Operational Update", "Delete"];
		}
		return ["View", "Create", "Edit", "Delete"];
	};

	const renderPermissions = (role: any, record: any) => {
		const permissions = getPermissionsByFeature(record.feature);
		return permissions.map((action) => {
			const permission = `${action}.${record.feature}`;
			return (
				<div key={action}>
					<Space>
						<Form.Item
							name={[role, record.feature, action]}
							valuePropName="checked"
						>
							<Checkbox
								checked={record.roles[role]?.includes(permission)}
								onChange={(e) =>
									handleCheckboxChange(
										record.key,
										permission,
										role,
										e.target.checked
									)
								}
							/>
						</Form.Item>
						<Form.Item>{action}</Form.Item>
					</Space>
				</div>
			);
		});
	};

	const ROLE_TOOLTIPS: Record<string, string> = {
		tenant_admin: "Permissions for users added as tenant admin of a tenant.",
		tenant_members: "Permissions for users added as tenant member of a tenant.",
		project_manager:
			"Permissions for users assigned as a 'Project manager' of a project.",
		team_members: "Permissions for users assigned to a role in a project.",
		general_members:
			"Permissions for users assigned as general member of a project.",
	};

	const columns = [
		{
			title: "Features",
			dataIndex: "feature",
			key: "feature",
			render: (_: any, record: any) => <strong>{record.feature}</strong>,
		},
		...ROLES.map((role) => ({
			title: (
				<Tooltip title={ROLE_TOOLTIPS[role]}>
					{role
						.split("_")
						.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
						.join(" ")}
					<QuestionCircleOutlined style={{ marginLeft: 4, opacity: "0.45" }} />
				</Tooltip>
			),
			key: role,
			render: (_: any, record: any) => renderPermissions(role, record),
		})),
	];

	const resetForm = async (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			if (values) {
				const _form: any = {
					tenant_admin: {
						Project: { View: false, Create: false, Edit: false, Delete: false },
						Well: { View: false, Create: false, Edit: false, Delete: false },
						Operation: {
							View: false,
							Create: false,
							Edit: false,
							"Operational Update": false,
							Delete: false,
						},
					},
					tenant_members: {
						Project: { View: false, Create: false, Edit: false, Delete: false },
						Well: { View: false, Create: false, Edit: false, Delete: false },
						Operation: {
							View: false,
							Create: false,
							Edit: false,
							"Operational Update": false,
							Delete: false,
						},
					},
					project_manager: {
						Project: { View: false, Create: false, Edit: false, Delete: false },
						Well: { View: false, Create: false, Edit: false, Delete: false },
						Operation: {
							View: false,
							Create: false,
							Edit: false,
							"Operational Update": false,
							Delete: false,
						},
					},
					team_members: {
						Project: { View: false, Create: false, Edit: false, Delete: false },
						Well: { View: false, Create: false, Edit: false, Delete: false },
						Operation: {
							View: false,
							Create: false,
							Edit: false,
							"Operational Update": false,
							Delete: false,
						},
					},
					general_members: {
						Project: { View: false, Create: false, Edit: false, Delete: false },
						Well: { View: false, Create: false, Edit: false, Delete: false },
						Operation: {
							View: false,
							Create: false,
							Edit: false,
							"Operational Update": false,
							Delete: false,
						},
					},
				};

				await values.map((feature: any) => {
					for (const role in feature.roles) {
						feature.roles[role].map((perm: any) => {
							const [process, _feat] = perm.split(".");
							_form[role][_feat][process] = true;
						});
					}
				});

				formRef.setFieldsValue(_form);
			} else {
				formRef.setFieldsValue(null);
			}
		}
	};

	const handleFormSave = () => {
		Emitter.emit("loading", true);

		// Update rolePermissionData
		const _tableData = [...rolePermissionTableData];
		const _roles = [...rolePermissionData];
		_roles.map((role) => {
			let _perm: string[] = [];
			_tableData.map((feature) => {
				if (role.key in feature.roles) {
					const rp = feature.roles[role.key];
					_perm = [..._perm, ...rp];
				}
			});
			role.permissions = Array.from(new Set(_perm));
		});
		setRolePermissionData(_roles);

		// Call api save here
		updateRolePermissions(context.currentTenantKey, _roles, props.params.user)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: `Role permissions has been updated.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				context?.handleFormUpdating(false);

				updateAllData(_roles, "update", "role_permissions");

				// update initial data and form
				resetForm(rolePermissionTableData);
			})
			.catch((error: any) => {
				console.log(error);
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to update permissions. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const handleFormCancel = () => {
		context?.handleFormUpdating(false);
		resetForm(initialTableData ?? initialTableData);
	};

	return (
		<>
			{context.currentTenantKey && (
				<div className="crud-tab-table-modal-container tenant-settings-tab">
					<div className="main-container">
						<div className="generic-header">Permission settings</div>
						<div
							className="generic-content"
							style={{
								display: "flex",
								flexDirection: "column",
								padding: "16px",
							}}
						>
							<span style={{ fontSize: "12px", paddingLeft: "0px" }}>
								{GetAntIcon("question3")} Customise permissions to control each
								role's access to features and data.
							</span>
							<Form
								form={formRef}
								style={{ width: "100%", paddingRight: "0px" }}
							>
								<Table
									dataSource={rolePermissionTableData}
									columns={columns}
									pagination={false}
									bordered
								/>
							</Form>
						</div>
						<div className="generic-footer">
							<FormButtonCancel
								form={formRef}
								handleFormCancel={handleFormCancel}
								formReset={formReset}
							/>
							<FormButtonSave
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default PermissionSettings;
