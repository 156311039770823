import React, { useEffect, useMemo, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import { Button, Divider } from "antd";
import { WindowsFilled } from "@ant-design/icons";

const times = [
	{ time: "8:00AM" },
	{ time: "9:00AM" },
	{ time: "10:00AM" },
	{ time: "11:00AM" },
	{ time: "12:00PM" },
	{ time: "1:00PM" },
	{ time: "2:00PM" },
	{ time: "3:00PM" },
	{ time: "4:00PM" },
	{ time: "5:00PM" },
	{ time: "6:00PM" },
	{ time: "7:00PM" },
	{ time: "8:00PM" },
	{ time: "9:00PM" },
	{ time: "10:00PM" },
	{ time: "11:00PM" },
	{ time: "12:00AM" },
	{ time: "1:00AM" },
	{ time: "2:00AM" },
	{ time: "3:00AM" },
	{ time: "4:00AM" },
	{ time: "5:00AM" },
	{ time: "6:00AM" },
	{ time: "7:00AM" },
];

function TimeBlock(props: any) {
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [activeColumn, setActiveColumn] = useState<string | null>(null);
	const [highlightedCells, setHighlightedCells] = useState(new Set<string>());
	const [renderedEntries, setRenderedEntries] = useState([]);

	// Filter Handling
	const [filterMode, setFilterMode] = useState<Boolean>(false);

	// Changes
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		// Define a function to handle window resize
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		// Add the resize event listener
		window.addEventListener("resize", handleResize);

		// Cleanup function to remove the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const startHighlighting = (cellKey: string, columnKey: string) => {
		if (columnKey !== activeColumn) {
			// New column selected, clear previous highlights
			setHighlightedCells(new Set([cellKey]));
		} else {
			// Same column, toggle current cell
			setHighlightedCells((prev) => {
				const newSet = new Set(prev);
				if (newSet.has(cellKey)) {
					newSet.delete(cellKey);
				} else {
					newSet.add(cellKey);
				}
				return newSet;
			});
		}
		setActiveColumn(columnKey); // Update active column
	};

	// Time Entry Container
	useEffect(() => {
		const timeEntries = props.timeEntries.map((entry: any, index: any) => {
			// Find the corresponding day in `daysOfWeek`
			const daysofWeekEntry = props.daysOfWeek.find((date: any) => {
				console.log(date.day, entry.date);
				return date.day === entry.date.slice(0, 10);
			});

			const entryProject = props.projects.find((project: any) => {
				return project._id === entry.project_id;
			});

			if (daysofWeekEntry) {
				let dayCell = document.getElementById(`0-${daysofWeekEntry.day}`);
				if (dayCell) {
					return (
						<div
							key={index}
							style={{
								position: "absolute",
								top: `${dayCell.offsetTop}px`, // y-axis
								left: `${dayCell.offsetLeft}px`, // x-axis
								background: "#131344",
								height: `${
									entry.duration_hours * 100 + (entry.duration_mins / 60) * 100
								}px`,
								width: `${dayCell.clientWidth}px`,
							}}
						>
							<div
								onClick={(e) => {
									props.handleSelectedTimeEntryData(
										daysofWeekEntry.day,
										entry.project_id
									);
									props.handleShowModalCell(
										e,
										daysofWeekEntry.name,
										entry.project_id,
										"button"
									);
								}}
								style={{
									display: "flex",
									flexDirection: "column",
									gap: "16px",
									padding: "12px 16px",
								}}
							>
								<span
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "8px",
									}}
								>
									<span>{entryProject.project_name}</span>
									<span style={{ color: "grey" }}>{GetAntIcon("map")}</span>
								</span>
								<Button className="ant-btn-primary">{`${entry.duration_hours}h ${entry.duration_mins}m`}</Button>
							</div>
						</div>
					);
				}
			}
			return <React.Fragment key={index}></React.Fragment>;
		});
		setRenderedEntries(timeEntries);
	}, [props.timeEntries, props.daysOfWeek, windowSize]);

	useEffect(() => {
		if (props.openModal) {
			setHighlightedCells(new Set<string>());
		}
	}, [props.openModal]);

	return (
		<>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "220px repeat(7, 1fr)",
				}}
			>
				{/* Timewriting Header Second Row */}
				<div
					style={{
						padding: "12px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						gridColumn: "1", // Ensures the "Time" section stays in the first column
					}}
				>
					{/* Time */}
					<div
						style={{
							display: "flex",
							flex: 1,
							justifyContent: "space-between",
						}}
					>
						<span>Time</span>
					</div>
					{/* Filter */}
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "8px",
						}}
					>
						{/* Filter Button */}
						<span
							className={filterMode ? "project-active-button" : ""}
							style={{
								padding: "4px 10px",
								cursor: "pointer",
							}}
							onClick={() => {
								setFilterMode(!filterMode);
							}}
						>
							{GetAntIcon("filter")}
						</span>
					</div>
				</div>

				{/* Day Columns */}
				{props.daysOfWeek.map((day: any) => (
					<div
						key={day.day}
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "12px",
						}}
					>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span style={{ fontSize: "20px" }}>{day.dayNumber}</span>
							<span>{day.name}</span>
						</div>
						<div style={{ color: "aqua" }}>
							{props.handleCalculateDayDuration(day.day)}
						</div>
					</div>
				))}
			</div>

			{/* Table Rows */}
			<div
				className="timewriting-table-body"
				style={{
					display: "grid",
					gridTemplateColumns: "220px repeat(7, 1fr)",
					position: "relative",
				}}
			>
				{times.map((time: any, rowIndex: number) => (
					<>
						<div
							className="timewriting-table-main-col"
							style={{
								backgroundColor: "#131344",
								padding: "19px 16px",
								border: "1px solid grey",
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							<span style={{ textAlign: "left", fontSize: "16px" }}>
								{time.time}
							</span>
						</div>

						{props.daysOfWeek.map((day: any) => {
							const cellKey = `${rowIndex}-${day.day}`;
							const columnKey = `${day.day}`;

							return (
								<div
									id={cellKey}
									key={cellKey}
									className="timewriting-table-col"
									style={{
										// padding: "12px 16px",
										backgroundColor: highlightedCells.has(cellKey)
											? "#FFFFFF20"
											: "#FFFFFF40",
										border: "1px solid grey",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										transition: "background-color 0.3s ease",
										cursor: "pointer",
										position: "relative",
										height: "100px",
									}}
									onMouseEnter={() => {
										if (isMouseDown && columnKey === activeColumn) {
											setHighlightedCells((prev) => new Set(prev).add(cellKey));
										}
									}}
									// onMouseLeave={handleMouseLeave}
									onMouseDown={() => {
										console.log("onMouseDown", cellKey);
										setIsMouseDown(true);
										startHighlighting(cellKey, columnKey);
									}}
									onMouseUp={() => {
										console.log("onMouseUp", cellKey);
										setIsMouseDown(false);
										props.setOpenModal(true);
										props.handleSelectedTimeEntryData(day.day, null);
										console.log("highlightedcells", highlightedCells);
									}}
								></div>
							);
						})}
					</>
				))}
				{/* For each time entry
				 -> find the starting cell 
				 -> document.getElementbyId("cell Id")
				 -> get the offsetTop
				 -> pass the offset Top to time entry div 
				 -> calculate the height of the time entry div */}
				{/* For every `timeEntries`, map them out */}
				<div>{renderedEntries}</div>
			</div>
		</>
	);
}

export default TimeBlock;
