import { useContext, useEffect, useState } from "react";
import CRUDTabTableModal from "../../components/CRUDTabTableModal";
import OperationConfigModal from "../../components/Modal/OperationConfigModal";
import { HomeContext } from "../../containers/Home";
import usePermission from "../../hooks/usePermission";
import { getWells } from "../../services/api-server/wells";
import Emitter from "../../utils/emitter";

const ProjectOutline = (props: any) => {
	const context: any = useContext(HomeContext);

	const [otherData, setOtherData] = useState<any>(null);
	const [operationModalOpen, setOperationModalOpen] = useState<boolean>(false);
	const [operationRecord, setOperationRecord] = useState<any>(null);
	const [wellList, setWellList] = useState<any>(null);
	const [userPermissions, setUserPermissions] = useState<string[] | null>(null);

	const hasCreateWellPermission = usePermission(
		"Create.Well",
		props?.currentProject
	);
	const hasCreateOperationPermission = usePermission(
		"Create.Operation",
		props?.currentProject
	);

	useEffect(() => {
		setOtherData({ project_code: props.currentProject });

		getWells(context.currentTenantKey, {
			projects: { $ne: props.currentProject },
		})
			.then((data: any) => {
				setWellList(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [props.currentProject]);

	useEffect(() => {
		const handleOperationModal = (data: any) => {
			setOperationModalOpen(true);
			setOperationRecord(data);
		};
		Emitter.on("operationModalOpen", handleOperationModal);
		return () => {
			Emitter.off("operationModalOpen", handleOperationModal);
		};
	}, []);

	useEffect(() => {
		const permissions = [
			"update",
			"add-existing_well",
			"delete",
			...(hasCreateWellPermission ? ["add-well"] : []),
			...(hasCreateOperationPermission ? ["add-operations"] : []),
		];
		setUserPermissions(permissions);
	}, [hasCreateWellPermission, hasCreateOperationPermission]);

	return (
		<>
			{props.currentProject !== null && context?.allData && otherData && (
				<CRUDTabTableModal
					items={[
						{
							label: "Well management",
							key: "well_operation",
							directoryName: "Well directory",
							// addItemPrivilege: ["tenant-admin", "project-admin"],
						},
					]}
					tabKey={"project-outline"}
					otherData={otherData}
					headerName={"Project outline"}
					user={props?.user}
					userPermissions={userPermissions}
				/>
			)}
			<OperationConfigModal
				open={operationModalOpen}
				setOpen={setOperationModalOpen}
				setOperationRecord={setOperationRecord}
				operationRecord={operationRecord}
				extraData={context.allData}
				user={props?.user}
				userRole={props?.userRole}
				currentProjectData={props.currentProjectData}
			/>
		</>
	);
};

export default ProjectOutline;
