import React, { useEffect, useState } from "react";
import {
	getNotifications,
	getReadStatus,
	getReadStatuses,
	getUserExists,
	getUserNotificationStatus,
	postNotificationToUsers,
	updateAllNotificationStatuses,
	updateAllReadStatuses,
	updateNotificationStatus,
	updateReadStatus,
	updateUserExists,
} from "../services/api-server/notification";
import { Dropdown, List, Button, Popover } from "antd";
import { well_delivered_contents } from "../containers/help_center/HelpCenter";
import { GetAntIcon } from "../utils/ant_icons";

export default function NotificationComponent(props: any) {
	interface Notification {
		_id: string;
		message: string;
		notification_type: string;
		created_on: string;
		receipients: Array<any>; // Modify this to match the exact structure of receipients if needed
	}

	const [userExists, setUserExists] = useState<any>(null);

	const [dropdownNotification, setDropdownNotification] = useState<
		Notification[]
	>([]);
	const [popOverNotification, setPopOverNotification] = useState<any>(null);
	const [userNotificationStatus, setUserNotificationStatus] =
		useState<any>(null);
	const [userReadStatus, setUserReadStatus] = useState<any>(null);
	const [userReadStatuses, setUserReadStatuses] = useState<any>(null);

	const [refresh, setRefresh] = useState<any>(null);
	const [versionNumber, setVersionNumber] = useState<any>(null);
	const [alertCount, setAlertCount] = useState<any>(null);

	useEffect(() => {
		const updatesFeature = well_delivered_contents.find(
			(item: any) => item.key === "updates-new-features"
		);

		if (updatesFeature && updatesFeature.descendants) {
			const latestVersion = updatesFeature.descendants.sort(
				(a: any, b: any) => {
					const versionA = a.key.split("v")[1].split(".").map(Number);
					const versionB = b.key.split("v")[1].split(".").map(Number);

					// Compare versions by major, minor, patch
					for (let i = 0; i < versionA.length; i++) {
						if (versionA[i] > versionB[i]) return -1;
						if (versionA[i] < versionB[i]) return 1;
					}
					return 0;
				}
			)[0];

			if (latestVersion) {
				const latestVersionNumber = latestVersion.label.split(" ")[1];
				console.log("Latest version: ", latestVersionNumber);

				// Fetch notifications directly
				getNotifications()
					.then((notifications: any) => {
						if (notifications && notifications.length > 0) {
							const newestDatabaseDocument = notifications.sort(
								(a: any, b: any) =>
									new Date(b.created_on).getTime() -
									new Date(a.created_on).getTime()
							)[0];

							console.log("Newest database document:", newestDatabaseDocument);

							if (
								newestDatabaseDocument.notification_type === latestVersionNumber
							) {
								console.log(
									"Version numbers match, no update and notification required."
								);
							} else {
								console.log(
									"Version numbers do not match, update and notification required."
								);

								updateAllNotificationStatuses(
									newestDatabaseDocument.notification_type
								)
									.then(() => {
										console.log(
											"Updating all notification statuses of previous users to `true`"
										);
									})
									.catch((error) => {
										console.error(
											"Error updating all users notifications statuses: ",
											error
										);
									});

								updateAllReadStatuses(newestDatabaseDocument.notification_type)
									.then(() => {
										console.log(
											"Updating all read statuses of previous users to `true`"
										);
									})
									.catch((error) => {
										console.error(
											"Error updating all users read statuses: ",
											error
										);
									});

								postNotificationToUsers(
									"Please check out our newest version",
									latestVersionNumber
								)
									.then(() => {
										console.log("New notification posted, refreshing page.");
										window.location.reload();
									})
									.catch((error) => {
										console.error("Error posting notification: ", error);
									});
							}
						} else {
							console.log("Notification collection is empty.");

							postNotificationToUsers(
								"Please check out our newest version",
								latestVersionNumber
							)
								.then(() => {
									console.log("New notification posted, refreshing page.");
									window.location.reload();
								})
								.catch((error) => {
									console.error("Error posting notification: ", error);
								});
						}
						setVersionNumber(latestVersionNumber);
					})
					.catch((error) => {
						console.error("Error fetching notifications:", error);
					});
			} else {
				console.log("No data can be found in 'label' field.");
			}
		} else {
			console.log(
				"No 'updates-new-features' and 'descendants' lists can be found."
			);
		}
	}, []); // This effect runs once when the component mounts

	// Second useEffect: Get notifications and user notification status when versionNumber changes
	useEffect(() => {
		// Get Dropdown Notifications
		getNotifications()
			.then((response: any) => {
				setDropdownNotification(response);

				if (response && response.length) {
					setAlertCount(response.length);
				} else {
					setAlertCount(0); // Reset alertCount if no notifications
				}
			})
			.catch((error: any) => {
				console.error("Error fetching dropdown notifications:", error);
				setAlertCount(0);
			});

		// Get User Notification Status
		const handleGetUserNotificationStatus = async () => {
			try {
				const status = await getUserNotificationStatus(
					props.userInfo.user.email,
					versionNumber
				);
				setUserNotificationStatus(status);
				console.log("Fetched Notification Status:", status);
			} catch (error) {
				setUserNotificationStatus(null);
				console.error("Error fetching user notification status:", error);
			}
		};

		// Get User Read Status
		const handleGetUserReadStatus = async () => {
			try {
				const status = await getReadStatus(
					props.userInfo.user.email,
					versionNumber
				);
				setUserReadStatus(status);

				console.log("Fetched Read Status:", status);
			} catch (error) {
				setUserReadStatus(null);
				console.error("Error fetching user read status:", error);
			}
		};

		const handleGetUserReadStatuses = async () => {
			try {
				const status = await getReadStatuses(props.userInfo.user.email);
				setUserReadStatuses(status);

				console.log("Fetched Read Statuses: ", status);
			} catch (error) {
				setUserReadStatuses(null);
				console.error("Error fetching user read status: ", error);
			}
		};

		handleGetUserNotificationStatus();
		handleGetUserReadStatus();
		handleGetUserReadStatuses();
	}, [versionNumber, refresh]); // The reason why we want this to run after `versionNumber` is obtained because we don't want it to be undefined

	useEffect(() => {
		if (dropdownNotification && versionNumber) {
			const handleGetUserExists = async () => {
				try {
					const status = await getUserExists(
						props.userInfo.user.email,
						versionNumber
					);
					setUserExists(status);
					console.log("Fetched User Exists: ", status);
				} catch (error) {
					setUserExists(null);
					console.error("Error fetching user existence: ", error);
				}
			};

			handleGetUserExists();
		}
	}, [dropdownNotification, versionNumber]);

	useEffect(() => {
		// Get Popover Notification
		if (userNotificationStatus !== null && userNotificationStatus === false) {
			getNotifications()
				.then((notifications: any) => {
					if (notifications && notifications.length > 0) {
						const newestDatabaseDocument = notifications.sort(
							(a: any, b: any) =>
								new Date(b.created_on).getTime() -
								new Date(a.created_on).getTime()
						)[0];
						console.log("Poover", newestDatabaseDocument);
						setPopOverNotification(newestDatabaseDocument);
					}
				})
				.catch((error: any) => {
					console.log(error);
				});
		} else {
			setPopOverNotification(null);
		}
	}, [userNotificationStatus]);

	// Put User Notification Status
	const handleUpdateNotificationStatus = async () => {
		console.log("Commencing Notification Status Update");

		try {
			const status = await updateNotificationStatus(
				props.userInfo.user.email,
				versionNumber
			);
			setUserNotificationStatus(status);
			console.log("Updated Notification Status", status);
		} catch (error) {
			console.log(error);
		}
	};

	const handleUpdateReadStatus = async () => {
		console.log("Commencing Read Status Update");

		try {
			const status = await updateReadStatus(
				props.userInfo.user.email,
				versionNumber
			);
			setUserReadStatus(status);
			console.log("Updated Read Status", status);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (userExists !== null && userExists === false) {
			const handleUpdateUserExists = async () => {
				console.log("Commencing Update User Exists");

				try {
					const status = await updateUserExists(
						props.userInfo.user.email,
						versionNumber
					);
					setUserExists(status);
					window.location.reload();
					console.log("Updated User Exists", status);
				} catch (error) {
					console.log(error);
				}
			};

			handleUpdateUserExists();
		}
	}, [userExists]);

	return (
		<div>
			<Dropdown
				dropdownRender={() => (
					<div style={{ width: "100%" }}>
						<div
							style={{
								padding: "8px 16px",
								maxWidth: "400px",
								boxShadow:
									"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
								borderRadius: "8px",
							}}
						>
							<List
								dataSource={dropdownNotification
									.slice() // Creates a shallow copy to avoid mutating the original array
									.sort(
										(a, b) =>
											new Date(b.created_on).getTime() -
											new Date(a.created_on).getTime()
									)} // Sort newest to oldest
								renderItem={(item, index) => (
									// Notification List Item
									<List.Item key={index} style={{ padding: "8px 16px" }}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: "10px",
											}}
										>
											{/* Red Badge */}
											{userReadStatus !== null &&
												userReadStatuses.some(
													(status: any) =>
														status.notification_type ===
															item.notification_type && status.read === false
												) && (
													<div
														style={{
															width: "7px",
															height: "7px",
															borderRadius: "50%",
															backgroundColor: "#FF4D4F",
															display: "inline-block",
															flexShrink: 0,
															alignSelf: "center",
														}}
													/>
												)}
											{/* Notification List Icon */}
											<span className="notification-list-icon">
												{GetAntIcon("notification")}
											</span>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
												}}
											>
												<p style={{ margin: "0px" }}>
													<span style={{ fontSize: "14px" }}>
														<span style={{ fontWeight: "bold" }}>
															Version {item.notification_type}{" "}
														</span>
														<span>{item.message}</span>
													</span>
													<Button
														type="link"
														style={{ float: "right" }}
														onClick={async () => {
															try {
																handleUpdateReadStatus();
																setRefresh((prev: any) => !prev);
																window.location.href = `/help-center/well-delivered?tab=updates-new-features&fromButtonClick=true`;
															} catch (error) {
																console.log(error);
															}
														}}
													>
														Read more
													</Button>
												</p>
												{/* Date and Time */}
												<span
													style={{
														color: "var(--comment-box-date-time)",
													}}
												>
													{new Date(item.created_on).toLocaleString()}
												</span>
											</div>
										</div>
									</List.Item>
								)}
							/>
						</div>
					</div>
				)}
				trigger={["click"]}
			>
				{/* Popover Button */}
				<Button
					style={{
						width: "32px",
						height: "32px",
						position: "relative",
						padding: "8px",
						borderRadius: "50%",
					}}
				>
					{/* Red Dot Count */}
					{userReadStatus !== null &&
						!userReadStatus &&
						alertCount &&
						userExists !== null &&
						userExists !== false && (
							<div
								style={{
									position: "absolute",
									top: "-3px",
									right: "0px",
									width: "12px",
									height: "12px",
									borderRadius: "50%",
									backgroundColor: "#FF4D4F",
									display: "inline-block",
								}}
							>
								<div style={{ fontSize: "8px" }}>{alertCount}</div>
							</div>
						)}
					{/* Popover */}
					{popOverNotification &&
					userNotificationStatus !== null &&
					userNotificationStatus === false &&
					userExists &&
					userExists !== null &&
					userExists !== false ? (
						<Popover
							placement="leftBottom"
							open={!userNotificationStatus}
							// Set `onOpenChange` event listener based on `userNotificationStatus` (false by default)
							// Everytime the user `onBlurs` it will set the `userNotificationStatus` to true
							onOpenChange={(userNotificationStatus) => {
								// If `userNotificationStatus` has been set to true
								if (!userNotificationStatus) {
									handleUpdateNotificationStatus();
									console.log("Updating user notification.");
								} else {
									console.log("User Notification already updated.");
								}
							}}
							title={
								<div
									style={{
										maxWidth: "300px",
										padding: "4px",
										display: "flex",
										flexDirection: "column",
										alignItems: "start",
										gap: "8px",
									}}
								>
									<div
										style={{
											fontSize: "14px",
											fontWeight: "normal",
										}}
									>
										<div style={{ display: "flex", gap: "8px" }}>
											<span className="notification-list-icon">
												{GetAntIcon("notification")}
											</span>
											<span style={{ fontWeight: "bold" }}>
												{`We just released Version ${popOverNotification.notification_type}!`}
											</span>
										</div>
										<div
											style={{
												fontSize: "14px",
												fontWeight: "normal",
											}}
										>
											{popOverNotification.message}
										</div>
										<Button
											type="link"
											style={{ float: "right" }}
											onClick={async () => {
												try {
													handleUpdateNotificationStatus();
													handleUpdateReadStatus();
													setRefresh((prev: any) => !prev);
													window.location.href = `/help-center/well-delivered?tab=updates-new-features&fromButtonClick=true`;
												} catch (error) {
													console.log(error);
												}
											}}
										>
											Read more
										</Button>
									</div>
								</div>
							}
						>
							<span style={{ fontSize: "16px" }}>{GetAntIcon("bell")}</span>
						</Popover>
					) : (
						<span style={{ fontSize: "16px" }}>{GetAntIcon("bell")}</span>
					)}
				</Button>
			</Dropdown>
		</div>
	);
}
