import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../containers/Home";
import { getProject, getProjects } from "../services/api-server/projects";

function useCheckGuestAccount(): boolean {
    const context: any = useContext(HomeContext);
    const userInfo: any = context?.userInfo;

    const [isGuest, setIsGuest] = useState<boolean>(true);

    useEffect(() => {
        if (!context.allData?.projects) return;

        const allProjects = context.allData.projects;
        if (allProjects) {
            const USER_EMAIL = userInfo.user.email.toLowerCase();
            const userRole = userInfo.userRole;

            if (
                userRole.includes(process.env.REACT_APP_SUPER_ADMIN_TAG) ||
                userRole.includes("tenant-admin") ||
                userRole.includes("tenant-members")
            ) {
                setIsGuest(false);
                return;
            }

            for (const project of allProjects) {
                const PROJECT_MANAGER = project?.project_manager?.toLowerCase() || "";
                const TEAM_MEMBERS: Array<string> = Array.from(
                    new Set(
                        project?.role_assignments?.flatMap((role: any) =>
                            role.appointee.map((email: string) => email.toLowerCase())
                        )
                    )
                ) || [];
                const GENERAL_MEMBERS: Array<string> = project?.general_members.map((email: string) => email.toLowerCase()) || [];

                if (USER_EMAIL === PROJECT_MANAGER || TEAM_MEMBERS.includes(USER_EMAIL) || GENERAL_MEMBERS.includes(USER_EMAIL)) {
                    setIsGuest(false)
                    break;
                }
            }
        }
    }, [context.currentTenantKey, isGuest, context.allData])

    console.log("User is a guest:", isGuest)
    return isGuest;
}

export default useCheckGuestAccount;
