import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../containers/Home";
import { getProject, getProjects } from "../services/api-server/projects";
import Emitter from "../utils/emitter";

function usePermission(permission: string, currentProjectCode: string | null = null): boolean {
    const context: any = useContext(HomeContext);
    const userInfo: any = context?.userInfo;

    const [role, setRole] = useState<string | null>(null);
    const [rolePermissions, setRolePermissions] = useState<any[]>([]);

    useEffect(() => {
        const fetchProjectAndSetRole = async () => {
            if (!userInfo) return;

            const USER_EMAIL = userInfo.user.email.toLowerCase();
            let ROLE = "guest";

            if (userInfo.userRole.includes("tenant-admin")) {
                ROLE = "tenant_admin";
            } else if (userInfo.userRole.includes("tenant-members")) {
                ROLE = "tenant_members";
            } else if (currentProjectCode) {
                try {
                    if (!context.allData?.projects) return;
                    // const projects: any = await getProject(context.currentTenantKey, currentProjectCode);
                    const projects: any = context.allData.projects;
                    const project = projects.find((item: any) => item.project_code == currentProjectCode)
                    if (project) {
                        const PROJECT_MANAGER = project?.project_manager?.toLowerCase() || "";
                        const TEAM_MEMBERS: Array<string> = Array.from(
                            new Set(
                                project?.role_assignments?.flatMap((role: any) =>
                                    role.appointee.map((email: string) => email.toLowerCase())
                                )
                            )
                        ) || [];
                        const GENERAL_MEMBERS: Array<string> = project?.general_members.map((email: string) => email.toLowerCase()) || [];

                        if (USER_EMAIL === PROJECT_MANAGER)
                            ROLE = "project_manager";
                        else if (TEAM_MEMBERS.includes(USER_EMAIL))
                            ROLE = "team_members";
                        else if (GENERAL_MEMBERS.includes(USER_EMAIL))
                            ROLE = "general_members";
                    }
                } catch (error) {
                    console.error("Failed to get project", error);
                }
            }
            else {
                try {
                    if (!context.allData?.projects) return;

                    // const allProjects: any = await getProjects(context.currentTenantKey);
                    const allProjects: any = context.allData.projects;
                    for (const project of allProjects) {
                        const PROJECT_MANAGER = project?.project_manager?.toLowerCase() || "";
                        const TEAM_MEMBERS: Array<string> = Array.from(
                            new Set(
                                project?.role_assignments?.flatMap((role: any) =>
                                    role.appointee.map((email: string) => email.toLowerCase())
                                )
                            )
                        ) || [];
                        const GENERAL_MEMBERS: Array<string> = project?.general_members.map((email: string) => email.toLowerCase()) || [];

                        if (USER_EMAIL === PROJECT_MANAGER) {
                            ROLE = "project_manager";
                            break;
                        }
                        else if (TEAM_MEMBERS.includes(USER_EMAIL)) {
                            ROLE = "team_members";
                            break;
                        }
                        else if (GENERAL_MEMBERS.includes(USER_EMAIL)) {
                            ROLE = "general_members";
                            break;
                        }
                    }
                } catch (error) {
                    console.error("Failed to get projects", error);
                }
            }
            setRole(ROLE);
            setRolePermissions(context.allData?.role_permissions || []);
            Emitter.emit("loading", false);
        };

        Emitter.emit("loading", true);
        fetchProjectAndSetRole();
    }, [context.currentTenantKey, currentProjectCode, userInfo, context.allData]);

    const calculatePermission = (): boolean => {
        if (userInfo?.userRole?.includes(process.env.REACT_APP_SUPER_ADMIN_TAG)) {
            console.log("User role: super-admin")
            return true; // Super admin has all permissions
        }
        if (role && rolePermissions.length > 0) {
            console.log("User role:", role)
            const _role = rolePermissions.find((roleItem: any) => roleItem.key === role);
            return _role?.permissions?.includes(permission) || false;
        }
        return false;
    };

    return calculatePermission();
}

export default usePermission;
