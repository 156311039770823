import { useMemo, useRef, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import { Divider, Button, InputRef, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CheckboxDropdown from "../components/CheckboxDropdown";
import TimeEntryModal from "../components/Modal/TimeEntryModal";

function TimewritingWeeklySummary(props: any) {
	// Filter States
	const inputRef = useRef<InputRef | null>(null); // Use InputRef here
	const [filteredProjectList, setFilteredProjectList] = useState<any>(null);
	// Search project by name
	const [searchHovered, setSearchHovered] = useState<any>(false);
	const [searchShow, setSearchShow] = useState<any>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	// Fitler project by tenant
	const [selectedFilters, setSelectedFilters] = useState<any>({
		tenant: [],
	});
	// Sort project by alphabet
	const [descMode, setDescMode] = useState<boolean>(false);
	// Cursor
	const [hoveredCell, setHoveredCell] = useState<string | null>(null);

	// Filter Handling
	// useMemo: Ensures the filterRender component is only re-created when filterMode or selectedFilters changes, improving performance by avoiding unnecessary re-renders.
	const filterRender = useMemo(() => {
		// This function is called when a filter's selection changes.
		const handleMenuClick =
			// field: Represents the filter category (e.g., "location").
			// selectedValues: The selected values for the filter category.
			(field: any, selectedValues: any[]) => {
				setSelectedFilters({ ...selectedFilters, [field]: selectedValues });
				// console.log("handleMenuClick - selectedFilters: ", selectedFilters);

				// Stores the updated filters in localStorage so the selections persist across page reloads.
				localStorage.setItem(
					"selectedFilters",
					JSON.stringify({ ...selectedFilters, [field]: selectedValues })
				);
			};

		return (
			<div>
				{/* Tenant */}
				<CheckboxDropdown
					options={props.tenants.map((tenant: any) => ({
						label: tenant.displayName,
						value: tenant.role_key,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("tenant_key", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["tenant_key"]}
					Key="tenant_key"
					label={GetAntIcon("filter")}
					page={"timewriting"}
				/>
			</div>
		);
	}, [props.projects, selectedFilters]);

	const listProjects = () => {
		const filterProjectList = (
			// To store `projects`
			projectList: any[],
			// filters is an object where each key represents a filter field (tenant)
			filters: any
		) => {
			return projectList.filter((project) => {
				// console.log("Processing Project: ", project);
				// Converts the `filters` object into an array of key-value pairs
				return Object.entries(filters).every(([field, selectedValues]) => {
					// console.log("Field:", field, "Selected Values:", selectedValues);

					// If the array is empty (no selectedValue) then skip this section
					if (!Array.isArray(selectedValues) || selectedValues.length === 0)
						return true;

					// Normalize the field name (e.g., trim and lowercase)
					const normalizedField = field.trim().toLowerCase();
					// console.log("Normalized Field:", normalizedField);

					// Normalize project keys to lowercase
					const normalizedProjectKeys = Object.keys(project).map((key) =>
						key.toLowerCase()
					);
					// console.log("Normalized Project Keys:", normalizedProjectKeys);

					// Check if the normalizedField is present in the project keys
					if (!normalizedProjectKeys.includes(normalizedField)) {
						console.warn(`Field '${normalizedField}' not found in project.`);
						return false;
					}

					// Get the value of the field from the project object
					const projectValue = project[normalizedField];
					// console.log(
					// 	`Field Value from Project: ${normalizedField} = ${projectValue}`
					// );

					return selectedValues.includes(projectValue);
				});
			});
		};

		// console.log("Selected Filters:", selectedFilters);
		// Declare a variable to hold the filtered list of projects.
		const filteredProjectList =
			selectedFilters && Object.keys(selectedFilters).length > 0
				? (() => {
						// Log that filtering is being applied
						// console.log("Filtering projects with filters:", selectedFilters);

						// Log the filtered list (assuming filterProjectList is well-defined)
						const filtered = filterProjectList(props.projects, selectedFilters);
						// console.log("Filtered Projects List:", filtered);
						return filtered;
				  })()
				: (() => {
						// Log that no filters are applied and the full list is used
						// console.log("No filters applied, returning full projects list.");
						return props.projects;
				  })();

		const searchFilterList = (projectList: any[], searchTerm: string) => {
			if (!searchTerm) return projectList;
			return projectList.filter((project) =>
				project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
			);
		};

		const searchedProjectList = searchFilterList(
			filteredProjectList,
			searchValue
		);

		const sortProjectList = (projectList: any[], descMode: boolean) => {
			return [...projectList].sort((a, b) => {
				return descMode
					? b.project_name.localeCompare(a.project_name) // Descending
					: a.project_name.localeCompare(b.project_name); // Ascending
			});
		};

		let sortedProjectList = sortProjectList(searchedProjectList, descMode);

		let currentProjectList =
			sortedProjectList || searchedProjectList || props.projects;

		if (currentProjectList.length > 0) {
			return (
				<div>
					{currentProjectList.map((project: any) => (
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "220px repeat(7, minmax(105px, 1fr))", // First column is 220px, others have a min width of 105px
								height: "96px",
							}}
						>
							{/* Project Column */}
							<div
								key={project._id}
								className="timewriting-table-main-col"
								style={{
									backgroundColor: "#131344",
									padding: "12px 16px",
									border: "1px solid grey",
									display: "flex",
									flexDirection: "column",
									gap: "8px",
								}}
							>
								<span>{project.project_name}</span>
								<span style={{ color: "grey", display: "flex", gap: "4px" }}>
									{GetAntIcon("map")} {project.location}
								</span>
							</div>

							{/* Time Entry Duration Columns */}
							{props.daysOfWeek.map((day: any) => (
								<div
									key={`${project._id}-${day.day}`}
									className="timewriting-table-col"
									style={{
										padding: "12px 16px",
										backgroundColor:
											hoveredCell === `${project._id}-${day.day}`
												? "#FFFFFF20"
												: "#FFFFFF40",
										border: "1px solid grey",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										transition: "background-color 0.3s ease",
										cursor: "pointer",
									}}
									onMouseEnter={() =>
										handleMouseEnterCell(project._id, day.day)
									}
									onMouseLeave={handleMouseLeaveCell}
									onClick={(e) => {
										// Run `handleShowModalCell`
										props.handleShowModalCell(e, day.name, project._id, "div");
										// Run `handleSelectedTimeEntryData` (preset `tenant_key`, `project_id`, and `date`)
										props.handleSelectedTimeEntryData(day.day, project._id);
									}}
								>
									{/* If handleSetTimeEntryRowCol has been run */}
									{props.handleSetTimeEntryRowCol(day.day, project._id) && (
										<Button
											className="ant-btn-primary"
											onClick={(e) => {
												e.stopPropagation();
												props.handleShowModalCell(
													e,
													day.name,
													project._id,
													"button"
												);
												props.handleSelectedTimeEntryData(day.day, project._id);
											}}
										>
											{props.handleSetTimeEntryRowCol(day.day, project._id)}
										</Button>
									)}
								</div>
							))}
						</div>
					))}
				</div>
			);
		}
	};

	// Cell Mouse Hover Handling
	const handleMouseEnterCell = (row: number, day: string) => {
		setHoveredCell(`${row}-${day}`);
	};

	const handleMouseLeaveCell = () => {
		setHoveredCell(null);
	};

	return (
		<>
			{/* Header Second Row */}
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "220px repeat(7, 1fr)", // 1 fixed + 7 equal columns
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							flexGrow: 1,
							color: searchHovered ? "aqua" : "inherit",
							cursor: searchHovered ? "pointer" : "default",
						}}
						onMouseEnter={() => setSearchHovered(true)}
						onMouseLeave={() => setSearchHovered(false)}
						onClick={() => {
							setSearchShow((prev: any) => !prev);
							setTimeout(() => {
								if (!searchShow && inputRef.current) {
									inputRef.current.focus();
								}
							}, 0);
						}}
					>
						{/* First Column Left Container */}
						<div>
							{searchShow ? (
								<>
									{/* Search projects input */}
									<Input
										ref={inputRef} // Attach the ref to the Input component
										placeholder="Search by operation name"
										className="input-clearable-panel"
										style={{
											maxWidth: "265px",
										}}
										onPressEnter={(
											e: React.KeyboardEvent<HTMLInputElement>
										) => {
											setSearchValue(e.currentTarget.value);
											e.currentTarget.blur();
										}}
										onChange={(e) => {
											const value = e.target.value;
											setSearchValue(value);

											setFilteredProjectList(
												props.projects.filter((project: any) =>
													project.project_name
														.toLowerCase()
														.includes(value.toLowerCase())
												)
											);
										}}
										allowClear
										prefix={<SearchOutlined />}
									/>
								</>
							) : (
								<>
									{/* List of projects */}
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<div style={{ padding: "4px 10px" }}>
											{GetAntIcon("search")}
										</div>
										<div>List of projects</div>
									</div>
								</>
							)}
						</div>
					</div>
					{/* Right Container */}
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "end",
							width: "fit-content",
						}}
					>
						{/* Filter Button */}
						<span
							style={{
								padding: "4px 10px",
								cursor: "pointer",
							}}
						>
							{filterRender}
						</span>
						{/* Sorting Button */}
						<span
							style={{
								padding: "4px 10px",
								cursor: "pointer",
							}}
							onClick={() => {
								setDescMode(!descMode);
							}}
						>
							{descMode ? GetAntIcon("descending") : GetAntIcon("ascending")}
						</span>
					</div>
				</div>
				{/* Days of week mapping */}
				{props.daysOfWeek.map((day: any) => (
					<div style={{ display: "flex", alignItems: "center" }}>
						<Divider type="vertical" style={{ margin: 0 }} />
						<div
							style={{
								width: "100%",
								padding: "12px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<div
								key={day.day}
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								{/* Day number (01) */}
								<span style={{ fontSize: "20px" }}>{day.dayNumber}</span>
								{/* Day name (Monday) */}
								<span>{day.name}</span>
							</div>
							{/* Time entries total duration columns calculation */}
							<div style={{ color: "aqua" }}>
								{props.handleCalculateDayDuration(day.day)}
							</div>
						</div>
					</div>
				))}
			</div>
			{/* Table Body */}
			{listProjects()}
		</>
	);
}

export default TimewritingWeeklySummary;
