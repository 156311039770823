import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";

const apiURL = "timewriting";

export const getAllTimeEntriesByTenant = () => {
	return new Promise((resolve, reject) => {
		const requestUrl = `${url}/${apiURL}`;
		console.log(requestUrl);
		axios
			.get(requestUrl, {
				...AuthHeader,
			})
			.then((response: any) => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					resolve(null);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching time entries: ", error);
				reject(error);
			});
	});
};

// export const postTimeEntryByTenant = (
// 	tenant_key: string,
// 	project_id: string,
// 	uuid: any,
// 	date: Date,
// 	time_type: string,
// 	start_time: number,
// 	end_time: number,
// 	duration_hours: number,
// 	duration_mins: number,
// 	description: string,
// 	created_on: Date,
// 	modified_on: Date
// ) => {
// 	return new Promise((resolve, reject) => {
// 		const requestURL = `${url}/${apiURL}`;
// 		console.log(requestURL, "URL");

// 		console.log(date);

// 		// Construct object for `time_entry`
// 		let time_entry: any = {
// 			uuid: uuid,
// 			tenant_key: tenant_key,
// 			date: date,
// 			time_type: time_type, // Ensure this matches the schema ("Duration" or "Start & End")
// 			start_time: start_time,
// 			end_time: end_time,
// 			duration_hours: duration_hours,
// 			duration_mins: duration_mins,
// 			description: description,
// 			created_on: created_on,
// 			modified_on: modified_on,
// 		};

// 		const requestBody = {
// 			project_id: project_id,
// 			time_entry: [time_entry], // Ensure time_entry is an array, as per your schema
// 		};

// 		axios
// 			.get(requestURL, {
// 				params: {
// 					filter: `{"project_id": "${project_id}"}`,
// 				},
// 				...AuthHeader,
// 			})
// 			.then((response) => {
// 				// If time entry under specified project_id already exists
// 				if (
// 					response.data &&
// 					Array.isArray(response.data) &&
// 					response.data.length > 0
// 				) {
// 					console.log(response.data);

// 					const project = response.data.find(
// 						(project: any) => project.project_id === project_id
// 					);

// 					if (project) {
// 						axios
// 							.put(
// 								requestURL,
// 								{
// 									filter: { project_id: project_id },
// 									data: {
// 										$push: {
// 											time_entry: {
// 												uuid: uuid,
// 												tenant_key: tenant_key,
// 												date: date,
// 												time_type: time_type,
// 												start_time: start_time,
// 												end_time: end_time,
// 												duration_hours: duration_hours,
// 												duration_mins: duration_mins,
// 												description: description,
// 												created_on: created_on,
// 												modified_on: modified_on,
// 											},
// 										},
// 									},
// 									options: {
// 										upsert: false,
// 									},
// 								},
// 								AuthHeader
// 							)
// 							.then((response) => {
// 								console.log("Time entry updated successfully:", response);
// 								resolve(response);
// 							})
// 							.catch((error) => {
// 								console.error("Error updating time entry:", error);
// 								reject(error);
// 							});
// 					}
// 					// If time entry under specified project_id do not exists
// 				} else {
// 					axios
// 						.post(requestURL, requestBody, {
// 							...AuthHeader,
// 						})
// 						.then((response) => {
// 							console.log("Time entry created successfully: ", response);
// 							resolve(response);
// 						})
// 						.catch((error) => {
// 							console.error("Error creating time entry: ", error);
// 							reject(error);
// 						});
// 				}
// 			})
// 			.catch((error) => {
// 				console.error("Error creating time entry: ", error);
// 				reject(error);
// 			});
// 	});
// };

// export const deleteTimeEntryByTenant = (project_id: string, uuid: any) => {
// 	return new Promise((resolve, reject) => {
// 		const requestURL = `${url}/${apiURL}`;
// 		console.log(requestURL, "Delete URL");

// 		console.log(project_id, uuid);

// 		axios
// 			.get(requestURL, {
// 				params: {
// 					filter: `{"project_id": "${project_id}"}`,
// 				},
// 				...AuthHeader,
// 			})
// 			.then((response) => {
// 				if (
// 					response.data &&
// 					Array.isArray(response.data) &&
// 					response.data.length > 0
// 				) {
// 					console.log(response.data);

// 					// Find the project within the response
// 					const project = response.data.find(
// 						(project: any) => project.project_id === project_id
// 					);

// 					if (project) {
// 						// Check the time entries within the found project
// 						const timeEntry = project.time_entry;

// 						// Find the time entry that matches the date
// 						const timeEntryIndex = timeEntry.findIndex((entry: any) => {
// 							return entry.uuid === uuid;
// 						});

// 						// If the index of that time entry is not found
// 						if (timeEntryIndex !== -1) {
// 							// Remove the time entry by its index in the array
// 							timeEntry.splice(timeEntryIndex, 1);

// 							// Proceed to update the project with the modified time_entry array
// 							axios
// 								.put(
// 									requestURL,
// 									{
// 										filter: { project_id: project_id }, // Ensure you're providing the right filter
// 										data: {
// 											time_entry: timeEntry, // Updated data
// 										},
// 									},
// 									{
// 										...AuthHeader,
// 									}
// 								)
// 								.then((response) => {
// 									console.log("Time entry deleted successfully: ", response);
// 									resolve(response);
// 								})
// 								.catch((error) => {
// 									console.error("Error updating the project: ", error);
// 									reject(error);
// 								});
// 						} else {
// 							console.log(
// 								"No matching time entry found for the specified uuid."
// 							);
// 							resolve(false); // No matching time entry found
// 						}
// 					} else {
// 						console.log("No matching project found for the project_id.");
// 						resolve(false); // No matching project found
// 					}
// 				} else {
// 					console.log(
// 						"No time entries documents found for the given project_id."
// 					);
// 					resolve(false); // No time entries found
// 				}
// 			})
// 			.catch((error) => {
// 				console.error("Error fetching project data: ", error);
// 				reject(error); // Handle error when fetching the project data
// 			});
// 	});
// };

// export const putTimeEntryByTenant = (
// 	project_id: string,
// 	uuid: any,
// 	date: Date,
// 	time_type: string,
// 	start_time: number,
// 	end_time: number,
// 	duration_hours: number,
// 	duration_mins: number,
// 	description: string,
// 	modified_on: Date
// ) => {
// 	return new Promise((resolve, reject) => {
// 		const requestURL = `${url}/${apiURL}`;
// 		console.log(requestURL, "Put URL");

// 		console.log(
// 			project_id,
// 			date,
// 			time_type,
// 			start_time,
// 			end_time,
// 			duration_hours,
// 			duration_mins,
// 			description,
// 			modified_on
// 		);

// 		axios
// 			.get(requestURL, {
// 				params: {
// 					filter: `{"project_id": "${project_id}"}`,
// 				},
// 				...AuthHeader,
// 			})
// 			.then((response) => {
// 				if (
// 					response.data &&
// 					Array.isArray(response.data) &&
// 					response.data.length > 0
// 				) {
// 					console.log(response.data);

// 					// Find the project within the response
// 					const project = response.data.find(
// 						(project: any) => project.project_id === project_id
// 					);

// 					if (project) {
// 						// Find the time entry that matches the uuid
// 						const timeEntry = project.time_entry;

// 						// Find the time entry that matches the uuid
// 						const timeEntryIndex = timeEntry.findIndex(
// 							(entry: any) => entry.uuid === uuid
// 						);

// 						console.log(timeEntryIndex, "index");

// 						if (timeEntryIndex !== -1) {
// 							// Update the specific fields based on what has changed
// 							const updatedEntry = timeEntry[timeEntryIndex];

// 							if (project_id) updatedEntry.project_id = project_id;
// 							if (date) updatedEntry.date = date;
// 							if (time_type) updatedEntry.time_type = time_type;
// 							if (start_time) updatedEntry.start_time = start_time;
// 							if (end_time) updatedEntry.end_time = end_time;
// 							if (duration_hours) updatedEntry.duration_hours = duration_hours;
// 							if (duration_mins) updatedEntry.duration_mins = duration_mins;
// 							if (description) updatedEntry.description = description;
// 							updatedEntry.modified_on = modified_on;

// 							axios
// 								.put(
// 									requestURL,
// 									{
// 										filter: { project_id: project_id },
// 										data: {
// 											time_entry: timeEntry,
// 										},
// 									},
// 									{
// 										...AuthHeader,
// 									}
// 								)
// 								.then((response) => {
// 									console.log("Time entry updated successfully: ", response);
// 									resolve(response);
// 								})
// 								.catch((error) => {
// 									console.error("Error updating the project: ", error);
// 									reject(error);
// 								});
// 						} else {
// 							console.log(
// 								"No matching time entry found for the specified uuid."
// 							);
// 							resolve(false);
// 						}
// 					} else {
// 						console.log("No matching project found for the project_id.");
// 						resolve(false);
// 					}
// 				} else {
// 					console.log(
// 						"No time entries documents found for the given project_id."
// 					);
// 					resolve(false);
// 				}
// 			})
// 			.catch((error) => {
// 				console.error("Error fetching project data: ", error);
// 				reject(error);
// 			});
// 	});
// };

export const createTimeEntry = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				console.error("Error occurred:", e); // Log the error for debugging
				const errorMessage =
					e?.response?.data?.error || "An unexpected error occurred";
				reject(errorMessage);
			});
	});
};

export const updateTimeEntry = (
	/* Parameters have to be in an ascending order when passing data */
	uuid: any,
	data: any,
	prevData: any
	// formList: any
) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${apiURL}`;
		console.log(requestURL, "Update URL");

		// Exclude `_id` and `uuid` from the data payload
		const sanitizedData = { ...data };
		delete sanitizedData.uuid; // Remove `uuid`
		delete sanitizedData._id; // Remove `_id`

		console.log(
			"`updateTimeEntry` UUID: ",
			uuid,
			"prevData",
			prevData,
			"sanitized data: ",
			sanitizedData
			// "formList: ",
			// formList
		);

		axios
			.put(
				`${url}/${apiURL}`,
				{
					filter: { uuid: uuid },
					data: sanitizedData,
					options: { upsert: true, new: true },
					prevData: prevData,
					// formList,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteTimeEntry = (uuid: any) => {
	return new Promise((resolve, reject) => {
		console.log("`deleteTimeEntry` UUID: ", uuid);

		axios
			.delete(`${url}/${apiURL}`, {
				params: {
					uuid: uuid,
				},
				...AuthHeader,
			})
			.then((_data) => {
				console.log("Deleteing data: ", _data);
				resolve(true);
			})
			.catch((e: any) => {
				console.error("Error deleting time entry:", e); // Log the error
				reject(e);
			});
	});
};
